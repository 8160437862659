<template>
  <ui-dropdown class="ui-button-selection">
    <template #trigger>
      <button
        type="button"
        class="ui-button main-button"
      >
        <ui-icon
          class="selection-icon"
          :value="icon"
          size="16"
        ></ui-icon>
        <span
          class="selection-count"
          v-text="count"
        ></span>
        <ui-icon
          class="dropdown-icon"
          value="g:arrow_drop_down"
        ></ui-icon>
      </button>
    </template>

    <template #contents="{toggle}">
      <ui-list
        selectable
        @click.native="toggle()"
      >
        <ui-item
          v-for="action in actions"
          :key="action.label"
          :text="action.label"
          @click="action.action()"
        ></ui-item>
      </ui-list>
    </template>
  </ui-dropdown>
</template>

<script>
import { UiIcon, UiDropdown, UiList, UiItem } from '../../components';

export default {
  name: 'ui-button-selection',

  components: {
    UiIcon,
    UiDropdown,
    UiList,
    UiItem
  },

  props: {
    count: {
      type: Number,
      required: false,
      default: 0
    },

    total: {
      type: Number,
      required: false,
      default: 0
    },

    actions: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  computed: {
    icon() {
      if (!this.count) {
        return 'g:check_box_outline_blank';
      }

      if (!this.total || this.count == this.total) {
        return 'g:check_box';
      }

      return 'g:indeterminate_check_box';
    }
  }
};
</script>

<style lang="scss">
.ui-button-selection {
  .main-button {
    .ui-icon {
      color: rgba(0, 0, 0, 0.7);
    }

    .selection-count {
      font-size: 1em;
      padding: 0 12px;
      line-height: 1em;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .dropdown-icon {
    height: 19px;
  }
}
</style>